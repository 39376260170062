import { Message } from "element-ui";
export default {
  methods: {
    copyText(val) {
      const input = document.createElement("input");
      input.setAttribute("value", val);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      Message.success("复制成功");
    },

    downloadFile(url, name) {
      // console.log('url == ', url)      
      // console.log('name == ', name)      
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.setRequestHeader("Content-Type", "application/json;charset=utf-8");
      xhr.responseType = "blob"; // 返回类型blob
      xhr.send(); // 发送ajax请求
      xhr.onreadystatechange = function () {
        // 监听属性xhr.readyState每次变化时会触发
        console.log('===', xhr.readyState)
        if (xhr.readyState === 4) {
          // 响应已返回
          var filename = '' 
          if (name) {
            filename = `${name}.${url.split(/\.(?=[^\.]+$)/)[1]}`
            console.log('===fileNmae===', filename)
          } else {
            filename = url
          }
          // 将`blob`对象转化成一个可访问的`url`
          let dataUrl = window.URL.createObjectURL(new Blob([xhr.response]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = dataUrl;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href); //URL.revokeObjectURL() 静态方法用来释放一个之前通过调用 URL.createObjectURL() 创建的已经存在的 URL 对象。当你结束使用某个 URL 对象时，应该通过调用这个方法来让浏览器知道不再需要保持这个文件的引用了。
          document.body.removeChild(link);
        } else {
          // console.log(xhr.response);
        }
      };
    },
  },
};
