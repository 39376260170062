<template>
    <div>
        <img src="@/assets/image/logo.png" alt="" srcset="" class="logo-img">
        <h3>金碳号后台服务管理系统</h3>
        <el-menu class="el-menu-vertical-demo" style="width: 200px" unique-opened :default-active="current" :default-openeds="openKeys">
            <template v-for="(item, index) in menuList">
                <el-submenu v-if="item.children && item.children.length > 0" :key="index.name" :index="item.name">
                    <span slot="title">
                        <i :class="item.icon"></i>
                        <span>{{ item.meta.title }}</span>
                    </span>
                    <el-menu-item v-for="child in item.children" :key="child.name" :index="child.name" @click="handleClick(child)" class="sub-title">{{ child.meta.title }}</el-menu-item>
                </el-submenu>
                <el-menu-item v-else :index="item.name" :key="index.name" @click="handleClick(item)">
                    <i :class="item.icon"></i>
                    <span>{{ item.meta.title }}</span>
                </el-menu-item>
            </template>
        </el-menu>
        <!-- <a-menu style="width: 256px" :selected-keys="[current]" :open-keys="openKeys" mode="inline" @openChange="openChange">
            <template v-for="(item, index) in menuList">
                <a-sub-menu v-if="item.children && item.children.length > 1" :key="item.name">
                    <span slot="title">
                        <a-icon :type="item.icon" />
                        <span>{{ item.meta.title }}</span>
                    </span>
                    <a-menu-item v-for="(child, subIndex) in item.children" :key="child.name"
                        @click="handleClick">{{ child.meta.title }}</a-menu-item>
                </a-sub-menu>
                <a-menu-item v-else :key="item.name" @click="handleClick">
                    <a-icon :type="item.icon" />
                    <span>{{ item.meta.title }}</span>
                </a-menu-item>
            </template>
        </a-menu> -->
    </div>
</template>

<script>
import { menuRoutes } from "@/router/routes";
export default {
    data() {
        return {
            menuList: [],
            current: 'overview',
            openKeys: [],
        }
    },
    methods: {
        handleClick(row) {
            this.current = row.name;
            this.$router.push({
                name: row.name
            }).catch(() => { })
        },

        initMenu() {
            this.menuList = menuRoutes;
            this.menuList.forEach(item => {
                if (item.children) {
                    item.children = item.children.filter(child => !child.meta.hideMenu)
                }
            })
            const { name, matched } = this.$route;
            if(matched.length > 2){
                this.openKeys = [matched[1].name]
            }
            if(matched[1]?.meta.allActive){
                this.current = matched[1].name;
            }else{
                this.current = name;
            }
        }
    },
    created() {
        this.initMenu()
    }
}
</script>

<style lang="scss" scoped>
.logo-img {
    width: 48px;
    height: 48px;
    display: block;
    margin: 12px auto;
}

h3 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 12px;
    font-size: 12px;
}

::v-deep .el-menu-item, ::v-deep .el-submenu__title{
    font-weight: bold;
    i{
        font-size: 16px;
        font-weight: bold;
    }
}
.sub-title{
    padding-left: 50px !important;
    box-sizing: border-box;
}
</style>