import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from "./routes";
import Store from '@/store';

Vue.use(VueRouter)

const router = new VueRouter({
  routes: routes
})

router.beforeEach((to, from, next) => {
    let pathList = to.matched.map(item => {
        // 修改当前页面title
        let title = item.meta.title;
        if(item.meta.changeTitle && to.query.id){
            title = title.replace('新增', '编辑')
        }
        return {
            title,
            path: item.path
        }
    });
    pathList.splice(0, 1)

    // 填充前置页面
    if(to.meta.prePage){
        pathList.splice(1, 0, {
            title: to.meta.prePage,
            path: to.matched[1].path + '/list'
        })
    }

    Store.commit('setBread', pathList)
    next()
})

export default router
