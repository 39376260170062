<template>
    <div class="basic-layout">
        <v-sidebar></v-sidebar>
        <div class="page-container">
            <v-header></v-header>
            <div class="main-container">
                <v-breadcrumb></v-breadcrumb>
                <div class="route-page">
                    <router-view style="min-height: 100%;"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vHeader from '@/components/common/Header'
import vSidebar from '@/components/common/Sidebar'
import vBreadcrumb from '@/components/common/Breadcrumb'

export default {
    components: {
        vHeader,
        vSidebar,
        vBreadcrumb
    },
    data() {
        return {

        }
    },
    methods: {},
    created() { }
}
</script>

<style lang="scss" scoped>
.basic-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;

    .page-container {
        display: inline-flex;
        flex: 1;
        flex-direction: column;
        overflow-x: auto;
        overflow-y: hidden;

        .main-container {
            padding: 12px 12px 0;
            display: flex;
            flex: 1;
            flex-direction: column;
            background-color: #f2f2f2;
            height: 100%;
            width: 100%;
            overflow: auto;
            box-sizing: border-box;
            min-width: 1260px;

            .route-page {
                flex: 1;
                border-radius: 4px;
            }
        }
    }
}</style>