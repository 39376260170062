<template>
    <el-breadcrumb v-if="list.length > 1" separator="/" style="margin-bottom: 10px;">
        <el-breadcrumb-item v-for="(item, index) in list" :key="index">{{ item.title }}</el-breadcrumb-item>
    </el-breadcrumb>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    computed: {
        list() {
            return this.$store.state.breadcrumbs
        }
    },
    methods: {

    },
    created() {

    },
}
</script>

<style lang="scss" scoped>
.el-breadcrumb {
    font-weight: bold;
    font-size: 15px !important;
}
</style>
