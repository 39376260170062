// 订单
import RouteLayout from '@/layout/route'
export default {
    path: 'order',
    name: 'order',
    redirect: '/order/list',
    meta: { title: '订单管理' },
    icon: 'el-icon-document',
    component: RouteLayout,
    children: [
        {
            path: 'list',
            name: 'orderList',
            meta: { title: '标准订单'},
            component: () => import('@/views/order/list')
        },
        {
            path: 'handle',
            name: 'orderHandle',
            meta: { title: '代持订单'},
            component: () => import('@/views/order/list/handle')
        },
        {
            path: 'detail',
            name: 'orderDetail',
            meta: { title: '订单详情', hideMenu: true, prePage: '订单列表' },
            component: () => import('@/views/order/detail')
        },
    ]
}