// 营销管理
import RouteLayout from '@/layout/route'
export default {
    path: 'sale',
    name: 'sale',
    meta: { title: '营销管理' },
    icon: 'el-icon-present',
    component: RouteLayout,
    children: [
        {
            path: 'auction',
            meta: { title: '竞拍' },
            redirect: '/sale/auction/list',
            name: 'auctionList',
            component: RouteLayout,
            children: [
                {
                    path: 'list',
                    name: 'auctionList',
                    meta: { title: '竞拍列表' },
                    component: () => import('@/views/sale/auction/list')
                },
                {
                    path: 'form',
                    name: 'auctionForm',
                    meta: { title: '新增竞拍', hideMenu: true, prePage: '竞拍列表', changeTitle: true },
                    component: () => import('@/views/sale/auction/form')
                }
            ]
        },
    ]
}