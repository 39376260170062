// 资讯管理
import RouteLayout from '@/layout/route'
export default {
    path: 'news',
    name: 'news',
    meta: { title: '资讯管理' },
    icon: 'el-icon-coin',
    component: RouteLayout,
    children: [
        {
            path: 'project',
            name: 'projectResearch',
            meta: { title: '项目调研' },
            redirect: '/news/project/list',
            component: RouteLayout,
            children: [
                {
                    path: 'list',
                    name: 'projectResearchList',
                    meta: { title: '项目列表' },
                    component: () => import('@/views/news/projectResearch/list')
                },
                {
                    path: 'detail',
                    name: 'projectResearchDetail',
                    meta: { title: '项目详情', hideMenu: true, prePage: '项目列表' },
                    component: () => import('@/views/news/projectResearch/detail')
                },
                {
                    path: 'form',
                    name: 'projectResearchForm',
                    meta: { title: '新增项目', hideMenu: true, prePage: '项目列表', changeTitle: true },
                    component: () => import('@/views/news/projectResearch/form')
                }
            ]
        },
    ]
}