import BasicLayout from '@/layout/basic'
import goods from './goods'
import order from './order'
import news from './news'
import sale from './sale'

const menuRoutes = [
    // {
    //     path: 'overview',
    //     name: 'overview',
    //     meta: { title: '概览' },
    //     icon: 'el-icon-pie-chart',
    //     component: () => import('@/views/overview')
    // },
    // {
    //     path: 'workbench',
    //     name: 'workbench',
    //     meta: { title: '工作台' },
    //     icon: 'el-icon-news',
    //     component: () => import('@/views/workbench')
    // },
    // 路由字段介绍：
    // allActive所有子菜单都活跃展示父标题
    // hideMenu隐藏菜单
    // prePage当前页面的前置菜单，用于面包屑导航
    // changeTitle 新增编辑同用页面，修改标题
    goods,
    order,
    news,
    sale,
    {
        path: 'setting',
        name: 'setting',
        meta: { title: '设置' },
        icon: 'el-icon-setting',
        component: () => import('@/views/setting')
    },
]

const routes = [
    {
        path: '/login',
        name: 'login',
        meta: { title: '登录' },
        component: () => import('@/views/login')
    },
    {
        path: '/',
        redirect: '/login',
        component: BasicLayout,
        children: menuRoutes
    },
]

export { menuRoutes, routes }