<template>
    <div class="page-header">
        <el-dropdown trigger="click" @command="handleCommand">
            <h3>你好, {{username}}</h3>
            <el-dropdown-menu slot="dropdown" style="width: 100px;">
                <el-dropdown-item command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
export default{
    data(){
        return{

        }
    },
    computed: {
        username(){
            let user = JSON.parse(localStorage.getItem('user'))
            return user.username
        }
    },
    methods:{
        handleCommand(command){
            switch (command) {
                case 'logout':
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    this.$router.push({
                        path: '/login'
                    })
                    break;
                default:
                    break;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.page-header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-end;

    h3 {
        cursor: pointer;
        margin: 18px 10px 0 auto;
    }
}
</style>