// 报价 即商品
import RouteLayout from '@/layout/route'
export default {
    path: 'goods',
    name: 'goods',
    redirect: '/goods/list',
    meta: { title: '报价管理', allActive: true },
    icon: 'el-icon-coin',
    component: RouteLayout,
    children: [
        {
            path: 'list',
            name: 'goodsList',
            meta: { title: '报价列表', hideMenu: true},
            component: () => import('@/views/goods/list')
        },
        {
            path: 'detail',
            name: 'goodsDetail',
            meta: { title: '报价详情', hideMenu: true, prePage: '报价列表' },
            component: () => import('@/views/goods/detail')
        },
        {
            path: 'form',
            name: 'goodsForm',
            meta: { title: '新增报价', hideMenu: true, prePage: '报价列表', changeTitle: true },
            component: () => import('@/views/goods/form')
        }
    ]
}